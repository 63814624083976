
































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { readGraphData } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { dispatchGetGraphData } from '@/store/main/actions';
import USDTActiveChart from "../../components/plan/usdt_active.vue";
import StratActiveChart from "./strat_chart_active.vue";
import TokenActiveChart from "./token_chart_active.vue";

Vue.component('USDTActiveChart', USDTActiveChart);
Vue.component('StratActiveChart', StratActiveChart);
Vue.component('TokenActiveChart', TokenActiveChart);


@Component
export default class News extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
  
  get gainUSDTActive() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.Gain_USDT_active;
    }
  }

  get activePlanRaisedPercentage() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.active_plan_AUM / 100000 * 100;
    }
  }

  get activePlanCurrentAUM() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.active_plan_AUM;
    }
  }

  get historyUSDTActive() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_active;
    }
  }

  get allocations() {
    const constants = readConstants(this.$store);
    let allocations: {ticker: string; value: string }[] = [];
    if (constants) {
      let rows = constants.active_plan_strats.split(","); // "a:100,b:200,c:300,d:400",
      for (let i = 0; i < rows.length; i++) {
        allocations.push({ticker: rows[i].split(":")[0], value: rows[i].split(":")[1]});
      }

      return allocations;
    }
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let versionID = "none";
    if (constants) {
      versionID = constants.version;
    }
    // console.log('check version');
    // console.log('current version: ' + versionID);

    if (versionID !== "none" && localStorage.getItem('vers') !== versionID) {
      // console.log('RELOADDDDDDDDDDDDDDD');
      localStorage.setItem('vers', versionID);
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}


